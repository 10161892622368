import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/code/src/components/layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Columns = makeShortcode("Columns");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Columns left="Braese" mdxType="Columns">
      <p>{`Wir agieren seit mehr als zehn Jahren sehr erfolgreich am Markt und expandieren kontinuierlich. Diese positive Unternehmensentwicklung verdanken wir unseren Mitarbeitern!`}</p>
      <p>{`Werden auch Sie Teil dieses außergewöhnlich hoch motivierten Teams!`}</p>
      <p>{`Tolle Kollegen, eine flexible Arbeitsumgebung, viele Freiräume, ein besonders kooperativer Umgang und aktiv gestalteter Erfahrungsaustausch prägen das Hitabis-Miteinander. Regelmäßige Weiterbildungen, verschiedene Vergünstigungen und offene Feedback-Gespräche machen die Hitabis GmbH zu einem attraktiven Arbeitgeber mit nahezu unbegrenzten Entwicklungsmöglichkeiten.`}</p>
      <p>{`Schauen Sie sich in unseren Stellenangeboten um. Aktuell suchen wir für unser eingespieltes Hitabis-Team:`}</p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/karriere/admin-mw/"
          }}>{`Server- und Netzwerkadministrator (m/w/d)`}</a></li>
      </ul>
    </Columns>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      